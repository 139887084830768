body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/images/BrainProt_Back_2.jpg");
  color: rgb(210,210,210); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-portalnavicon-home {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.contact-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.phospho-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}

.img_home {
  max-height: 300px;
  object-fit: contain;
}

table {
  table-layout:fixed;
}


#ibpm-accordion .accordion-button {
  color: black;
  background-color: #6699FF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #6699FF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#bdtm-accordion .accordion-button {
  color: black;
  background-color: #00FFFF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #00FFFF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#bdpm-accordion .accordion-button {
  color: black;
  background-color: #8CC63F;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #8CC63F;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#bdmc-accordion .accordion-button {
  color: black;
  background-color: #FCEE21;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #FCEE21;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#bddf-accordion .accordion-button {
  color: black;
  background-color: #F7931E;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #F7931E;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#hbda-accordion .accordion-button {
  color: black;
  background-color: #F15A24;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#xxxx-accordion .accordion-body {
  color: black;
  background-color: #F15A24;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

#hbdaCarousel .w-100 {
  width: 15% !important;
}

a.svg {
  position: relative;
  display: inline-block; 
}
a.svg:after {
  content: ""; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
}
