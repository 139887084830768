/*Footer Image Responsiveness and Centering*/
.footer_img {
    height: 80px;
    object-fit: contain;
    padding-left: 10px;
  }
  
  a img {             
    display: block;
    margin: 0 auto;
    border: none;
  }